import React from 'react';
import styled from 'styled-components';

const NewEventHeader = props => {
  const { topStat } = props;
  let legsAtt;
  let peopleAtt;

  if (topStat) {
    ({ peopleAtt, legsAtt } = topStat);
  }
  return (
    <EventHeaderDiv>
      <div className="stat-container">
        <span className="stat-label">L</span>
        {legsAtt}
      </div>
      <div className="stat-container">
        <span className="stat-label">P</span>

        {peopleAtt}
      </div>
    </EventHeaderDiv>
  );
};

export default NewEventHeader;

const EventHeaderDiv = styled.div`
  position: fixed;
  top: 56px;
  display: flex;
  flex-direction: row;
  z-index: 20;
  background-color: white;
  padding: 0px 8px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 5px 5px;
  box-shadow: 0 8px 20px 0 rgba(9, 30, 66, 0.12), 0 4px 12px rgba(0, 0, 0, 0.09);

  .stat-container {
    padding: 4px 12px;
    background-color: #ebecf0;
    margin: 8px;
    font-size: 22px;
    font-weight: 600;
    border-radius: 3px;
  }
  .stat-label {
    font-weight: 400;
    color: #97a0af;
    margin-right: 8px;
  }
`;
