import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-apollo-hooks';

import { FRONTLINE_QUERY } from './VolumeView';
import Connector from '../../Universal/Connector';
import StraightConnector from '../../Universal/StraightConnector';
import VolumeIbo from './VolumeIbo';

const IboCard = ({
  dayStat,
  month,
  year,
  selectedIbo,
  selectIbo,
  frontline,
  connectors,
  sponsorIsLast,
}) => {
  const [expandFrontline, setExpandFrontline] = useState(false);

  const handleToggle = () => {
    setExpandFrontline(!expandFrontline);
  };

  const newConnectors = [].concat(connectors);
  if (!frontline) {
    if (!sponsorIsLast) {
      newConnectors.push(<StraightConnector key={newConnectors.length} />);
    } else {
      newConnectors.push(<BlankConnector key={newConnectors.length} />);
    }
  }

  const { data } = useQuery(FRONTLINE_QUERY, {
    variables: {
      iboshipId: dayStat.iboship.id,
      month: month + 1,
      year,
      includeZero: false,
    },
    suspend: false,
  });

  return (
    <GroupContainer>
      <Container>
        {connectors.map(con => con)}
        {!frontline && (
          <LevelSpan>
            <Connector />
          </LevelSpan>
        )}

        <CardContainer selected={selectedIbo === dayStat.iboship.id}>
          <VolumeIbo
            dayStat={dayStat}
            toggleExpand={handleToggle}
            selectIbo={selectIbo}
          />
        </CardContainer>
      </Container>
      {!dayStat.iboship.platinum &&
        !expandFrontline &&
        data.frontlineMonth &&
        data.frontlineMonth.length > 0 && (
          <HasLegs depth={newConnectors.length} />
        )}
      {expandFrontline &&
        !dayStat.iboship.platinum &&
        data &&
        data.frontlineMonth &&
        data.frontlineMonth.map((stat, index) => (
          <div key={stat.id} className="downline-wrapper">
            <IboCard
              dayStat={stat}
              selectIbo={selectIbo}
              selectedIbo={selectedIbo}
              month={month}
              year={year}
              connectors={newConnectors}
              sponsorIsLast={index + 1 === data.frontlineMonth.length}
            />
            <HasLegs depth={newConnectors.length} />
          </div>
        ))}
    </GroupContainer>
  );
};

export default IboCard;

const HasLegs = styled.div`
  width: 6px;
  height: 30px;
  position: absolute;
  background-color: #505f79;
  z-index: 5;
  top: 10px;
  left: ${({ depth }) => -3 + 24 * depth}px;
  border-radius: 3px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 2px 4px -1px;
`;

const BlankConnector = styled.div`
  height: 38px;
  width: 24px;
`;

const Container = styled.div`
  display: flex;
`;

const LevelSpan = styled.div`
  position: relative;
  width: 24px;
  height: 36px;
  z-index: 1;
  margin-top: 4px;
`;

const GroupContainer = styled.div`
  display: inline-flex;
  flex: initial;
  flex-direction: column;
  align-items: flex-start;
  z-index: 3;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  .downline-wrapper {
    padding-left: 0px;
    display: inline-flex;
    flex-direction: row;
  }
`;

const CardContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: ${({ selected }) =>
    selected
      ? 'rgba(9, 30, 66, 0.50) 0px 10px 15px -1px, rgba(9, 30, 66, 0.61) 0px 0px 1px'
      : 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px'};

  border-radius: 3px;
  margin: 6px 0px;
  flex: none;
  overflow: hidden;
  z-index: 6;
  -webkit-transform: translate3d(0, 0, 0);
`;
