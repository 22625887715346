import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

import styled from 'styled-components';
import { withRouter } from 'react-router';

import Button from '@material-ui/core/Button';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import IboEventCard from './IboEventCard';
import NewEventHeader from './NewEventHeader';
import Loading from '../../Universal/Loading';
import EventsPicker from './EventsPicker';
import { USER_INFO_QUERY } from '../../User';

const EventView = ({ match }) => {
  const [open, setOpen] = useState(false);
  const [selectedIbo, setSelectedIbo] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const {
    data: { teamEventStats, event },
    loading: loadingEvent,
    // refetch,
  } = useQuery(EVENT_STRUCTURE_QUERY, {
    variables: { eventId: match.params.id || 3 },
    suspend: false,
  });

  const {
    data: { myIboship },
    loading: loadingUser,
  } = useQuery(USER_INFO_QUERY, {
    suspend: false,
  });

  let mainStat;
  if (myIboship && teamEventStats) {
    mainStat = []
      .concat(teamEventStats)
      .filter(x => x.iboship.id === myIboship.id);
  }

  return (
    <React.Fragment>
      <HeaderContainer>
        <AppBar position="fixed" style={{ boxShadow: 'none' }}>
          <Toolbar style={{ justifyContent: 'space-between' }}>
            <Typography variant="h6" color="inherit">
              {event ? event.name : 'Event Details'}
            </Typography>
          </Toolbar>
        </AppBar>
      </HeaderContainer>
      <HeaderPadding />
      <VolumeGroupContainer>
        {loadingEvent || loadingUser ? (
          <Loading />
        ) : (
          mainStat && (
            <IboEventCard
              stat={mainStat && mainStat[0]}
              statsArray={teamEventStats}
              selectIbo={_selectIbo}
              editMode={editMode}
              selectedIbo={selectedIbo && selectedIbo.id}
              connectors={[]}
              frontline
              style={{ marginBottom: 20 }}
              sponsorIsLast
            />
          )
        )}
      </VolumeGroupContainer>
      <NewEventHeader topStat={mainStat && mainStat[0]} />
      {/* <GroupWrapper>
        {loadingStructure ? (
          <Loading />
        ) : (
          <IboEventCard
            structure={eventStructure}
            changedStatsArray={changedStatsArray}
            // handleStatTrump={_updateStatsArray}
            topIbo
            style={{ marginTop: 130 }}
          />
        )}
      </GroupWrapper> */}

      <Button
        variant="fab"
        color="secondary"
        aria-label="Add"
        style={{
          position: 'fixed',
          bottom: 28,
          right: 16,
          margin: '0 auto',
          zIndex: 20,
        }}
        onClick={() => setOpen(true)}
      >
        <CalendarTodayRoundedIcon style={{ color: '#14213D' }} />
      </Button>
      <EventsPicker open={open} onClose={() => setOpen(false)} />
    </React.Fragment>
  );
  function _selectIbo(iboshipId) {
    setSelectedIbo(iboshipId);
  }
};

export default withRouter(EventView);

const HeaderPadding = styled.div`
  display: block;
  height: 120px;
  width: 100%;
`;
const VolumeGroupContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  margin-bottom: 80px;
  padding-left: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  color: #fff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  font-size: 18px;
  font-weight: 600;
  background-color: #253858;
  position: fixed;
  top: 0;
  z-index: 12;
`;

const EVENT_STRUCTURE_QUERY = gql`
  query EventStructureQuery($eventId: Int) {
    teamEventStats(eventId: 3) {
      id
      iboVol
      primaryAtt
      secondaryAtt
      legsAtt
      peopleAtt
      iboship {
        lastName
        id
        firstName
        secondaryFirstName
        sponsorId
      }
    }
    event(id: $eventId) {
      name
      categoryId
      locationName
      startTime
    }
  }
`;
