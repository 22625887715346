import React from 'react';
import styled from 'styled-components';
import { structureWarnings } from './utils';

const EventIbo = ({ stat, toggleExpand, selectIbo }) => {
  const { primaryAtt, secondaryAtt, legsAtt, peopleAtt, iboship } = stat;

  const quant =
    primaryAtt && secondaryAtt
      ? 2
      : primaryAtt
      ? 1
      : secondaryAtt
      ? 1
      : primaryAtt === false
      ? 0
      : null;
  const { legsWarning, peopleWarning } = structureWarnings(legsAtt, peopleAtt);
  const statusString = getStatusString(stat);
  return (
    <Card>
      <div className="flex-column">
        <LegsDiv legs={legsWarning}>{legsAtt}</LegsDiv>
      </div>
      <div className="flex-column">
        <NameDiv onClick={() => toggleExpand()}>
          {iboship && iboship.lastName}
        </NameDiv>
      </div>
      <div className="flex-column">
        <PeopleDiv people={peopleWarning} onClick={() => handleToggle()}>
          {peopleAtt}
        </PeopleDiv>
      </div>

      <div className="flex-column">
        <SpouseColumn
          modified={false}
          quant={quant}
          couple={iboship && iboship.secondaryFirstName}
          // onClick={() => _handleClick()}
        >
          {statusString && statusString}
        </SpouseColumn>
      </div>
    </Card>
  );

  function handleToggle() {
    selectIbo({
      name: stat.iboship.lastName,
      id: stat.iboship.id,
    });
  }

  function getStatusString(currentStat) {
    const {
      primaryAtt: primary,
      secondaryAtt: secondary,
      iboship: cIboship,
    } = currentStat;
    const status =
      primary && secondary
        ? `${cIboship.firstName} & ${cIboship.secondaryFirstName}`
        : primary
        ? `${cIboship.firstName ? cIboship.firstName : '+'}${
            cIboship.secondaryFirstName ? ' Only' : ''
          }`
        : secondary
        ? `${cIboship.secondaryFirstName} Only`
        : primary === false
        ? 'None'
        : '? ';
    return status;
  }
};
export default EventIbo;

const Card = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  flex: 1;

  z-index: 6;
  font-size: 16px;
  min-height: 38px;
  box-sizing: border-box;
  .flex-column {
    display: inline-flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    justify-content: center;
  }
`;

const CardSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 14px;
`;

const NameDiv = styled(CardSection)`
  font-weight: 500;
  flex-grow: 2;
`;

const PeopleDiv = styled(CardSection)`
  font-weight: 600;
  ${({ people }) =>
    people === 0
      ? `
      background-color: #dfe1e6;
  color: #42526e;
  border-left: 1px solid #42526e;
  border-right: 1px solid #42526e;
  `
      : people === 2
      ? `
  background-color: #FFF7D8;
  color: #FF8B00;
  border-left: 1px solid #FFC400;
  border-right: 1px solid #FFC400;
  `
      : people === 3
      ? `
  background-color: #FFD7CE;
  color: #BF2600;
  border-left: 1px solid #FF5E3A;
  border-right: 1px solid #FF5E3A;
  `
      : people === 1
      ? `
      background-color: #EEFDF5;
  color: #006644;
  border-left: 1px solid #57D9A3;
  border-right: 1px solid #57D9A3;
  `
      : `
  background-color: #dfe1e6;
  color: #42526e;
  border-left: 1px solid #42526e;
  border-right: 1px solid #42526e;
  `};
`;

const LegsDiv = styled(CardSection)`
  padding: 6px 10px 6px 12px;
  font-weight: 600;
  min-width: 38px;
  ${({ legs }) =>
    legs === 0
      ? `
      background-color: #dfe1e6;
  color: #8993A4;
  border-left: 3px solid #8993A4;
  border-right: 1px solid #8993A4;
  `
      : legs === 2
      ? `
  background-color: #FFF7D8;
  color: #FF8B00;
  border-left: 3px solid #FFC400;
  border-right: 1px solid #FFC400;
  `
      : legs === 3
      ? `
  background-color: #FFD7CE;
  color: #BF2600;
  border-left: 3px solid #FF5E3A;
  border-right: 1px solid #FF5E3A;
  `
      : legs === 1
      ? `
      background-color: #EEFDF5;
  color: #006644;
  border-left: 3px solid #57D9A3;
  border-right: 1px solid #57D9A3;
  `
      : `
  background-color: #dfe1e6;
  color: #42526e;
  border-left: 3px solid #42526e;
  border-right: 1px solid #42526e;
  `};
`;

const SpouseColumn = styled(CardSection)`
  min-width: 60px;
  flex: 1;
  justify-content: center;
  display: flex;
  color: ${props => {
    if (!props.modified) {
      if (props.quant === null || props.quant === 0) {
        return '#7A869A';
      }
      return ' #172b4d';
    }
  }};
  font-weight: 500;
  background-color: ${props => {
    if (!props.modified) {
      return '#fff';
    }
    if (props.quant === null) {
      return '#DFE1E6';
    }
    if (props.quant === 1) {
      if (props.couple === null) {
        return '#79F2C0';
      }
      return '#FF991F';
    }
    if (props.quant === 0) {
      return '#FF7452';
    }
    if (props.quant === 2) {
      return '#79F2C0';
    }
  }};
  border-right: ${props => {
    if (props.quant === null) {
      return '3px solid #DFE1E6';
    }
    if (props.quant === 1) {
      if (props.couple === null) {
        return '3px solid #79F2C0';
      }
      return '3px solid #FF991F';
    }
    if (props.quant === 0) {
      return '3px solid #FF7452';
    }
    if (props.quant === 2) {
      return '3px solid #79F2C0';
    }
  }};
`;
