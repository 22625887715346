import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context'; // test

import { withClientState } from 'apollo-link-state';

import { AUTH_TOKEN } from '../constants';

const cache = new InMemoryCache();

const stateLink = withClientState({
  cache,
});

// const uri = 'http://127.0.0.1:8000/graphql/';
const uri = 'https://passiveincomedetective.com/graphql/';

const link = new HttpLink({ uri });

const authLink = setContext(async (req, { headers }) => {
  const token = await localStorage.getItem(AUTH_TOKEN);

  return {
    ...headers,
    headers: { authorization: token ? `JWT ${token}` : null },
  };
});

const httpLinkWithAuthToken = authLink.concat(link);

const Client = new ApolloClient({
  link: ApolloLink.from([stateLink, httpLinkWithAuthToken]),
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'none',
    },
    query: {
      errorPolicy: 'none',
    },
    mutate: {
      errorPolicy: 'none',
    },
  },
});

export default Client;
