import React, { useState } from 'react';
import styled from 'styled-components';

import Connector from '../../Universal/Connector';
import StraightConnector from '../../Universal/StraightConnector';

import EventIbo from './EventIbo';

const IboEventCard = ({
  topIbo,
  stat,
  selectedIbo,
  selectIbo,
  frontline,
  connectors,
  sponsorIsLast,
  statsArray,
  editMode,
}) => {
  const [expandFrontline, setExpandFrontline] = useState(topIbo || false);

  // const handleToggle = () => {
  //   console.log('toggle handled');
  //   setExpandFrontline(!expandFrontline);
  // };

  const newConnectors = [].concat(connectors);
  if (!frontline) {
    if (!sponsorIsLast) {
      newConnectors.push(<StraightConnector key={newConnectors.length} />);
    } else {
      newConnectors.push(<BlankConnector key={newConnectors.length} />);
    }
  }

  let frontlineIbos;
  if (statsArray) {
    if (editMode) {
      frontlineIbos = []
        .concat(statsArray)
        .filter(x => x.iboship.sponsorId === stat.iboship.id);
    } else {
      frontlineIbos = []
        .concat(statsArray)
        .filter(
          x => x.iboship.sponsorId === stat.iboship.id && x.peopleAtt > 0
        );
    }
  }

  return (
    <GroupContainer>
      <IboContainer>
        {connectors.map(con => con)}
        {!frontline && (
          <LevelSpan>
            <Connector />
          </LevelSpan>
        )}
        <CardContainer>
          {stat && (
            <EventIbo
              stat={stat}
              toggleExpand={() => setExpandFrontline(!expandFrontline)}
              selectIbo={selectIbo}
            />
          )}
        </CardContainer>
      </IboContainer>
      {!stat.iboship.platinum &&
        !expandFrontline &&
        frontlineIbos.length > 0 && <HasLegs depth={newConnectors.length} />}
      {!stat.iboship.platinum &&
        frontlineIbos &&
        (expandFrontline || frontline) &&
        frontlineIbos.map((s, index) => (
          <div key={s.id} className="downline-wrapper">
            <IboEventCard
              stat={s}
              statsArray={statsArray}
              selectIbo={selectIbo}
              selectedIbo={selectedIbo}
              connectors={newConnectors}
              sponsorIsLast={index + 1 === frontlineIbos.length}
            />
            <HasLegs depth={newConnectors.length} />
          </div>
        ))}
    </GroupContainer>
  );
};

export default IboEventCard;

const BlankConnector = styled.div`
  height: 38px;
  width: 24px;
`;

const HasLegs = styled.div`
  width: 6px;
  height: 30px;
  position: absolute;
  background-color: #505f79;
  z-index: 5;
  top: 9px;
  left: ${({ depth }) => 1 + 24 * depth}px;
  border-radius: 3px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 2px 4px -1px;
`;

const LevelSpan = styled.div`
  position: relative;
  width: 24px;
  height: 36px;
  z-index: 1;
  margin-top: 4px;
`;

const IboContainer = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  margin-right: 60px;
  border-radius: 2px solid red;
`;

const GroupContainer = styled.div`
  display: inline-flex;
  flex: initial;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

const CardContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 8px 20px 0 rgba(9, 30, 66, 0.12), 0 4px 12px rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  margin: 4px;
  flex: none;
  overflow: hidden;
  position: relative;
  font-weight: 500;
  font-size: 16px;
`;
