import React from 'react';
import styled from 'styled-components';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import format from 'date-fns/format';
import Registration from './Registration';

const Notification = ({
  note: {
    iboshipId,
    lastName,
    firstName,
    secondaryFirstName,
    value,
    categoryId,
    timestamp,
    prevTimestamp,
  },
}) => {
  let prev = !timestamp
    ? null
    : formatDistanceStrict(new Date(timestamp), new Date(), {
        includeSeconds: true,
      });
  if (prev.includes('days') || prev.includes('day')) {
    prev = `${prev.split(' ')[0]}d`;
  } else if (prev.includes('hours') || prev.includes('hour')) {
    prev = `${prev.split(' ')[0]}h`;
  } else if (prev.includes('minutes') || prev.includes('minute')) {
    prev = `${prev.split(' ')[0]}m`;
  } else if (prev.includes('seconds') || prev.includes('second')) {
    prev = `${prev.split(' ')[0]}s`;
  } else {
    prev = format(new Date(timestamp), 'MMM do');
  }
  return (
    <NotificationCard>
      <div className="timelineSection" />
      <div className="mainSection">
        <div className="firstLine">
          <span>
            <span className="userName">
              {firstName} {secondaryFirstName && `& ${secondaryFirstName} `}
              {lastName}
            </span>{' '}
            <span className="iboNumber">| {iboshipId}</span>
          </span>
          <span>{prev}</span>
        </div>
        {parseInt(categoryId, 10) === 3 && (
          <Registration
            iboshipId={iboshipId}
            firstName={firstName}
            lastName={lastName}
            secondaryFirstName={secondaryFirstName}
            value={value}
            prevTimestamp={prevTimestamp}
          />
        )}
        {parseInt(categoryId, 10) === 2 && (
          <React.Fragment>
            <div>
              {firstName} {secondaryFirstName && `& ${secondaryFirstName} `} hit
              the <span className="bolder">{value}%</span> Bracket.
            </div>
            <div>
              Last hit it on {format(new Date(prevTimestamp), 'MMM do')} at{' '}
              {format(new Date(prevTimestamp), 'h:mm aaaa')}
            </div>
          </React.Fragment>
        )}
        {parseInt(categoryId, 10) === 1 && (
          <div>
            <div className="bigNote">NEW PIN!</div>
            {firstName} {secondaryFirstName && `& ${secondaryFirstName} `} hit
            the {value}% Bracket for the first time!
          </div>
        )}
      </div>
    </NotificationCard>
  );
};

export default Notification;

const NotificationCard = styled.div`
  padding: 16px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #dfe1e6;
  background-color: white;
  .mainSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 16px;
  }
  .userName {
    font-weight: 700;
  }
  .firstLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .timeSince {
    color: #687b9b;
  }
  .bigNote {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  .timelineSection {
    min-width: 48px;
    height: 48px;
    color: #fff;
    background-color: #c1c7d0;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    padding: 0px;
  }
  .bolder {
    font-weight: 600;
  }
  .iboNumber {
    color: #6b778c;
  }
`;
