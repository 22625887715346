import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';

const NewIboCard = ({ ibo }) => {
  const {
    lastName,
    firstName,
    secondaryFirstName,
    regDate,
    lineOfSponsorship,
  } = ibo;
  return (
    <NewIboDiv>
      <div className="new-info">
        <span className="reg-date"> {format(new Date(regDate), 'MMM do')}</span>
        <span className="ibo-name">
          {secondaryFirstName
            ? `${firstName} & ${secondaryFirstName}`
            : `${firstName} `}
          {lastName}
        </span>
      </div>
      <div className="upline">
        {lineOfSponsorship &&
          lineOfSponsorship.map((upline, i) => (
            <span
              key={upline.id}
              className={
                i === 0
                  ? 'top-upline'
                  : i === lineOfSponsorship.length - 1
                  ? 'bottom-upline'
                  : 'other-upline'
              }
            >
              {upline.lastName}
            </span>
          ))}
      </div>
    </NewIboDiv>
  );
};
export default NewIboCard;

const NewIboDiv = styled.div`
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dfe1e6;
  background-color: white;
  margin: 8px 0px;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(9, 30, 66, 0.12), 0 4px 12px rgba(0, 0, 0, 0.18);
  .top-upline {
    font-weight: 600;
    border-right: 1px solid #97a0af;
    padding-right: 8px;
  }
  .new-info {
    padding: 16px;
    font-size: 18px;
  }
  .upline {
    border-radius: 3px;
    overflow-x: auto;
    white-space: nowrap;
    padding: 16px;
    background-color: #ebecf0;
  }
  .reg-date {
    color: #97a0af;
    width: 80px;
    display: inline-block;
    white-space: nowrap;
  }
  .ibo-name {
    font-weight: 600;
    white-space: nowrap;
  }
  .other-upline {
    padding: 0px 8px;
    border-right: 1px solid #97a0af;
  }
  .bottom-upline {
    padding: 0px 8px;
    font-weight: 600;
  }
`;
