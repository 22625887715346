import React from 'react';
import styled from 'styled-components';
import VolSpan from '../../Universal/VolSpan';
import { howCloseToNextPin } from './utils/index';
import BracketSpan from '../../Universal/BracketSpan';

const VolumeIbo = ({ selectIbo, toggleExpand, dayStat }) => {
  const howClose = howCloseToNextPin(dayStat.awardPv);
  const { iboship, customerPv, awardPv, personalPv, bracket } = dayStat;
  const cvr = customerPv >= 5000;

  const leftDiv = iboship.platinum ? (
    <PlatinumDiv>P</PlatinumDiv>
  ) : bracket >= 25 ? (
    <SilverDiv>S</SilverDiv>
  ) : (
    <BracketDiv onClick={() => toggleExpand()} warning={howClose.warning}>
      <BracketSpan percent={bracket} />
    </BracketDiv>
  );

  return (
    <Card>
      <div className="flex-column">{leftDiv}</div>
      <div className="flex-column">
        <NameDiv onClick={() => toggleExpand()}>{iboship.lastName}</NameDiv>
      </div>
      <div className="flex-column">
        <PersonalDiv cvr={cvr} onClick={() => handleToggle()}>
          <VolSpan pv={personalPv} bolder={false} />
        </PersonalDiv>
      </div>

      <div className="flex-column">
        <GroupDiv onClick={() => handleToggle()}>
          <VolSpan pv={awardPv} bolder={false} />
        </GroupDiv>
      </div>
    </Card>
  );
  function handleToggle() {
    selectIbo({
      name: dayStat.iboship.lastName,
      id: dayStat.iboship.id,
    });
  }
};

export default VolumeIbo;

const Card = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  flex: 1;

  z-index: 6;
  font-size: 16px;
  min-height: 38px;
  box-sizing: border-box;
  .flex-column {
    display: inline-flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    justify-content: flex-start;
  }
`;

const CardSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 14px;
`;

const NameDiv = styled(CardSection)`
  font-weight: 500;
  flex-grow: 2;
`;
const GroupDiv = styled(CardSection)`
  font-weight: 600;
`;

const PersonalDiv = styled(CardSection)`
  font-weight: 600;
  border-left: ${({ cvr }) =>
    !cvr ? '2px solid #FF5630 ' : '2px solid #36B37E'};
  background-color: #ebecf0;
`;

const PlatinumDiv = styled(CardSection)`
  font-weight: 600;
  min-width: 42px;
  padding-left: 12px;
  padding-right: 10px;
  color: #fff;
  background-color: #0052cc;
`;
const SilverDiv = styled(CardSection)`
  font-weight: 600;
  min-width: 42px;
  padding-left: 12px;
  padding-right: 10px;
  color: #fff;
  background-color: #8993a4;
`;
const BracketDiv = styled(CardSection)`
  padding: 6px 10px 6px 12px;
  font-weight: 600;
  ${({ warning }) =>
    warning === 0
      ? `
  background-color: #EEFDF5;
  color: #006644;
  border-left: 3px solid #57D9A3;
  border-right: 1px solid #57D9A3;
  `
      : warning === 1
      ? `
  background-color: #FFF7D8;
  color: #FF8B00;
  border-left: 3px solid #FFC400;
  border-right: 1px solid #FFC400;
  `
      : warning === 2
      ? `
  background-color: #FFD7CE;
  color: #BF2600;
  border-left: 3px solid #FF5E3A;
  border-right: 1px solid #FF5E3A;
  `
      : warning === 3
      ? `
  background-color: #FFD7CE;
  color: #BF2600;
  border-left: 3px solid #FF5E3A;
  border-right: 1px solid #FF5E3A;
  `
      : `
  background-color: #dfe1e6;
  color: #42526e;
  border-left: 3px solid #42526e;
  border-right: 1px solid #42526e;
  `};
`;
