export function addCouple(currentStat, topIbo, sendUpChanges, handleStatTrump) {
  let changes = [];
  if (currentStat.people === 0) {
    const change = {};
    change.type = 'legChange';
    change.value = 1;
    changes = changes.concat(change);
  }
  //
  const iboshipChange = {};
  iboshipChange.type = 'iboshipChange';
  iboshipChange.value = 1;
  changes = changes.concat(iboshipChange);
  const peopleChange = {};
  peopleChange.type = 'peopleChange';
  peopleChange.value = 2;
  changes = changes.concat(peopleChange);
  console.log('AddCouple() Sending Up Changes: ', changes);
  if (!topIbo)
    sendUpChanges(changes, currentStat, topIbo, sendUpChanges, handleStatTrump);

  currentStat.primary = true;
  currentStat.secondary = true;
  currentStat.iboships += 1;
  currentStat.people += 2;
  handleStatTrump(currentStat);
}

export async function toggleSoloPrimary(
  currentStat,
  topIbo,
  sendUpChanges,
  handleStatTrump
) {
  let changes = [];
  if (currentStat.primary) {
    if (currentStat.people === 1) {
      const change = {};
      change.type = 'legChange';
      change.value = -1;
      changes = changes.concat(change);
    }
    currentStat.primary = false;
    currentStat.iboships -= 1;
    currentStat.people -= 1;

    const iboshipChange = {};
    iboshipChange.type = 'iboshipChange';
    iboshipChange.value = -1;
    changes = changes.concat(iboshipChange);
    const peopleChange = {};
    peopleChange.type = 'peopleChange';
    peopleChange.value = -1;
    changes = changes.concat(peopleChange);
    if (!topIbo) {
      await sendUpChanges(
        changes,
        currentStat,
        topIbo,
        sendUpChanges,
        handleStatTrump
      );
    }
  } else if (currentStat.primary === null) {
    if (currentStat.people === 0) {
      const change = {};
      change.type = 'legChange';
      change.value = 1;
      changes = changes.concat(change);
    }
    currentStat.primary = true;
    currentStat.iboships += 1;
    currentStat.people += 1;

    const iboshipChange = {};
    iboshipChange.type = 'iboshipChange';
    iboshipChange.value = 1;
    changes = changes.concat(iboshipChange);
    const peopleChange = {};
    peopleChange.type = 'peopleChange';
    peopleChange.value = 1;
    changes = changes.concat(peopleChange);
    if (!topIbo) {
      await sendUpChanges(
        changes,
        currentStat,
        topIbo,
        sendUpChanges,
        handleStatTrump
      );
    }
  } else {
    console.log("it's false, Jim. Setting null.");
    currentStat.primary = null;
    currentStat.secondary = null;
  }
  await handleStatTrump(currentStat);
}

export async function justPrimary(
  currentStat,
  topIbo,
  sendUpChanges,
  handleStatTrump
) {
  let changes = [];
  currentStat.secondary = false;
  currentStat.people -= 1;
  await handleStatTrump(currentStat);
  if (!topIbo) {
    const peopleChange = { type: 'peopleChange', value: -1 };
    changes = changes.concat(peopleChange);
    await sendUpChanges(
      changes,
      currentStat,
      topIbo,
      sendUpChanges,
      handleStatTrump
    );
  }
}
export async function removeSecondary(
  currentStat,
  topIbo,
  sendUpChanges,
  handleStatTrump
) {
  let changes = [];
  if (currentStat.people === 1) {
    const obj = { type: 'legChange', value: -1 };
    changes = changes.concat(obj);
  }
  if (!topIbo) {
    const iboshipChange = { type: 'iboshipChange', value: -1 };
    changes = changes.concat(iboshipChange);
    const peopleChange = { type: 'peopleChange', value: -1 };
    changes = changes.concat(peopleChange);
    await sendUpChanges(
      changes,
      currentStat,
      topIbo,
      sendUpChanges,
      handleStatTrump
    );
  }
  currentStat.secondary = false;
  currentStat.people -= 1;
  await handleStatTrump(currentStat);
}
export async function switchToSecondary(currentStat, handleStatTrump) {
  currentStat.secondary = true;
  currentStat.primary = false;
  await handleStatTrump(currentStat);
}

export async function downlineChanged(
  changes,
  currentStat,
  topIbo,
  sendUpChanges,
  handleStatTrump
) {
  let changesForUpline = [];
  changes.forEach(c => {
    if (c.type === 'legChange') {
      currentStat.legs += c.value;
    }
    if (c.type === 'peopleChange') {
      if (currentStat.people === 0 && c.value >= 1) {
        const obj = { type: 'legChange', value: 1 };
        changesForUpline = changesForUpline.concat(obj);
      } else if (currentStat.people + c.value === 0) {
        const objThree = { type: 'legChange', value: -1 };
        changesForUpline = changesForUpline.concat(objThree);
      }
      const objTwo = { type: 'peopleChange', value: c.value };
      changesForUpline = changesForUpline.concat(objTwo);
      currentStat.people += c.value;
    }
    if (c.type === 'guestChangeFirst') {
      const objGuest = { type: 'guestChangeFirst', value: c.value };
      changesForUpline = changesForUpline.concat(objGuest);
      currentStat.groupGuestsFirst += c.value;
      currentStat.groupGuests += c.value;
    }
    if (c.type === 'guestChangeSecond') {
      const objGuest = { type: 'guestChangeSecond', value: c.value };
      changesForUpline = changesForUpline.concat(objGuest);
      currentStat.groupGuestsSecond += c.value;
      currentStat.groupGuests += c.value;
    }
    if (c.type === 'guestChangeThird') {
      const objGuest = { type: 'guestChangeThird', value: c.value };
      changesForUpline = changesForUpline.concat(objGuest);
      currentStat.groupGuestsThird += c.value;
      currentStat.groupGuests += c.value;
    }
    if (c.type === 'iboshipChange') {
      const objFour = { type: 'iboshipChange', value: c.value };
      changesForUpline = changesForUpline.concat(objFour);
      currentStat.iboships += c.value;
    }
  });
  if (!topIbo) {
    await sendUpChanges(changesForUpline);
  }
  await handleStatTrump(currentStat);
}

export function getCurrentStat(trumpStats, structure) {
  const trumpStat =
    (trumpStats &&
      [].concat(trumpStats).filter(a => a.iboship_id === structure.id)[0]) ||
    null;
  const { eventStat } = structure;
  let currentStat = {};
  if (trumpStat) {
    currentStat = trumpStat;
  } else if (!trumpStat && eventStat) {
    currentStat = {
      iboship_id: structure.id,
      lastName: structure.lastName,
      id: eventStat.id,
      primary: eventStat.primary,
      secondary: eventStat.secondary,
      legs: eventStat.legs,
      people: eventStat.people,
      iboships: eventStat.iboships,
      personalGuests: eventStat.personalGuests,
      groupGuests: eventStat.groupGuests,
      personalGuestsFirst: eventStat.personalGuestsFirst,
      personalGuestsSecond: eventStat.personalGuestsSecond,
      personalGuestsThird: eventStat.personalGuestsThird,
      groupGuestsFirst: eventStat.groupGuestsFirst,
      groupGuestsSecond: eventStat.groupGuestsSecond,
      groupGuestsThird: eventStat.groupGuestsThird,
    };
  } else {
    currentStat = {
      iboship_id: structure.id,
      lastName: structure.lastName,
      primary: null,
      secondary: null,
      legs: 0,
      people: 0,
      iboships: 0,
      personalGuests: 0,
      groupGuests: 0,
      personalGuestsFirst: 0,
      personalGuestsSecond: 0,
      personalGuestsThird: 0,
      groupGuestsFirst: 0,
      groupGuestsSecond: 0,
      groupGuestsThird: 0,
      modified: false,
    };
  }
  return currentStat;
}

export function filterPlatinumLegs(structure) {
  if (!structure.frontline || structure.frontline.length < 1) return [];
  return []
    .concat(structure.frontline)
    .filter(a => a.platinum)
    .sort((a, b) => b.groupPv - a.groupPv);
}

export function filterNonPlatinumLegs(structure) {
  if (!structure.frontline || structure.frontline.length < 1) return [];
  return []
    .concat(structure.frontline)
    .filter(a => a.active && !a.platinum)
    .sort((a, b) => parseFloat(b.groupPv) - parseFloat(a.groupPv));
}

export function getStatusString(structure, currentStat) {
  const { primary, secondary } = currentStat;
  const statusString =
    primary && secondary
      ? `${structure.firstName} & ${structure.secondaryFirstName}`
      : primary
      ? `${structure.firstName ? structure.firstName : '+'}${
          structure.secondaryFirstName ? ' Only' : ''
        }`
      : secondary
      ? `${structure.secondaryFirstName} Only`
      : primary === false
      ? 'None'
      : '? ';
  return statusString;
}

export function structureWarnings(legs, people) {
  let ppl = 0;
  let lgs = 0;
  if ([1, 3, 4, 6, 7, 9, 10, 12, 13, 15, 16, 18, 20].includes(legs)) {
    lgs = 1;
  } else if (legs !== 0) {
    lgs = 2;
  }

  if (
    (legs < 3 && people > 9) ||
    (legs < 6 && people > 24) ||
    (legs < 9 && legs > 2 && people > 74) ||
    (legs < 12 && legs > 5 && people > 99)
  ) {
    lgs += 1;
  } else if (legs < 6 && people > 24) {
    lgs += 1;
  }
  if (people > 7 && people < 10) {
    ppl = 2;
    if (legs > 2) {
      ppl += 1;
    }
  } else if (people > 9 && people < 22) {
    ppl = 1;
    if (legs > 5) {
      ppl += 1;
    }
  } else if (people > 21 && people < 25) {
    ppl = 2;
    if (legs > 5) {
      ppl += 1;
    } else if (legs < 3) {
      ppl = 0;
    }
  } else if (people > 24 && people < 44) {
    ppl = 1;
    if (legs > 5) {
      ppl += 1;
    }
  } else if (people > 43 && people < 50) {
    ppl = 2;
    if (legs > 5) {
      ppl += 1;
    } else if (legs < 3) {
      ppl = 0;
    }
  } else if (people > 49 && people < 70) {
    ppl = 1;
    if (legs > 8) {
      ppl += 1;
    }
  } else if (people > 69 && people < 75) {
    ppl = 2;
    if (legs > 8) {
      ppl += 1;
    } else if (legs < 3) {
      ppl = 0;
    }
  } else if (people > 74 && people < 90) {
    ppl = 1;
    if (legs > 11) {
      ppl += 1;
    }
  } else if (people > 89 && people < 100) {
    ppl = 2;
    if (legs > 11) {
      ppl += 1;
    } else if (legs < 3) {
      ppl = 0;
    }
  }

  return { peopleWarning: ppl, legsWarning: lgs };
}

// 0-2 ;   gray - gray
// 1-8 ;   gray - red
// 1-15 ;  gray - green  ; legs escalated since people at next level
// 2-3 ;   red - gray    ;
// 2-9 ;   red - red     ;
// 3-4 ;   green - gray  ; people escalated since legs at next level
// 3-24;   green - red   ; red de-escalated since legs not close
// 7-24;   green - red   ;
// 9-45;   green - red

// rules:
// never de-escalate legs, only people if legs isn't close
// escalate legs any time people are at next level
// escalate people any time legs are at next level
