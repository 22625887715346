import React from 'react';
import styled from 'styled-components';

const Connector = () => (
  <Positioner>
    <svg
      style={{ zIndex: 1 }}
      width="13"
      height="53"
      viewBox="0 0 13 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0.5C1 8.0641 1.00002 40.7385 1 48C0.999992 50.5 2.5 52 5.21622 52C7.29189 52 8.56757 52 13 52"
        stroke="#C1C7D0"
        strokeWidth="2"
      />
    </svg>
  </Positioner>
);

export default Connector;

const Positioner = styled.div`
  position: absolute;
  z-index: 1;
  left: 12px;
  top: -33px;
`;
