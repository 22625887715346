import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

import { withApollo } from 'react-apollo';
import { Typography } from '@material-ui/core';

class Logout extends Component {
  componentDidMount() {
    const { client } = this.props;
    client.resetStore();
  }

  render() {
    const { history } = this.props;
    return (
      <ContentPage>
        <ContentWrapper>
          <LoginCard>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h6" gutterBottom>
                You are now logged out.{' '}
              </Typography>
              <Button variant="outlined" onClick={() => history.push('/login')}>
                Log In
              </Button>
            </div>
          </LoginCard>
        </ContentWrapper>
      </ContentPage>
    );
  }
}

export default withApollo(withRouter(Logout));

const LoginCard = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.09),
    0 15px 35px 0 rgba(37, 41, 82, 0.06);
  padding: 20px 30px;
  border-radius: 7px;
  margin: auto;
`;

const ContentPage = styled.div`
  margin: 0px;
  transition: margin-left 0.25s;
`;

const ContentWrapper = styled.div`
  align-items: center;
  justify-content: center;
  height: 60vh;
  min-height: 400px;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  position: relative;
`;
