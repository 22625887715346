import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Loading from '../Universal/Loading';
import StructureIbo from './StructureIbo';

const StructureView = () => {
  const [showInactive, setShowInactive] = useState(false);
  const { loading, error, data, refetch } = useQuery(USER_INFO_QUERY, {
    suspend: false,
  });
  if (error) return `User Info Request: ${error.message}`;

  let structure = [];
  let iboArray = [];
  if (!loading && data.myIboship) {
    ({ structure } = data.myIboship);
    iboArray = []
      .concat(JSON.parse(structure))
      .filter(a => (showInactive ? a : a.active));
    console.log(JSON.parse(data.myIboship.structure));
  }

  return (
    <StructureWrapper>
      <HeaderContainer>
        <AppBar position="fixed" style={{}}>
          <Toolbar style={{ justifyContent: 'space-between' }}>
            <Typography variant="h6" color="inherit">
              Settings
            </Typography>
            <Button color="inherit">Logout</Button>
          </Toolbar>
        </AppBar>
      </HeaderContainer>
      <div>
        <ContentWrapper>
          <StructureWrapper>
            <Button onClick={() => setShowInactive(!showInactive)}>
              Show Inactive
            </Button>
            {loading ? (
              <Loading />
            ) : (
              <StructureWrapper>
                <StructureIbo
                  viewMode="settings"
                  refetch={refetch}
                  iboship={iboArray[0]}
                  allIbos={iboArray}
                  depthArray={[]}
                />
              </StructureWrapper>
            )}
          </StructureWrapper>
        </ContentWrapper>
      </div>
    </StructureWrapper>
  );
};
export default StructureView;

export const USER_INFO_QUERY = gql`
  query MyiboshipQuery {
    myIboship {
      id
      structure
    }
  }
`;

const HeaderContainer = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  z-index: 12;
`;

const StructureWrapper = styled.div`
  position: relative;
  display: block;
`;

const ContentWrapper = styled.div`
  padding: 68px 12px 12px 12px;
  display: flex;
  position: relative;
`;
