export function findBracket(pv) {
  if (!pv) return null;
  if (pv < 100) return 0.0;

  if (pv < 300 && pv >= 100) return 0.03;

  if (pv < 600 && pv >= 300) return 0.06;

  if (pv < 1000 && pv >= 600) return 0.09;

  if (pv < 1500 && pv >= 1000) return 0.12;

  if (pv < 2500 && pv >= 1500) return 0.15;

  if (pv < 4000 && pv >= 2500) return 0.18;

  if (pv < 6000 && pv >= 4000) return 0.21;

  if (pv < 7500 && pv >= 6000) return 0.23;

  if (pv < 10000 && pv >= 7500) return 0.25;

  if (pv < 12500 && pv >= 10000) return 0.27;

  if (pv < 15000 && pv >= 12500) return 0.29;

  if (pv >= 15000) return 0.31;

  return null;
}

export function findDifferential(sponsorPv, downlinePv) {
  if (downlinePv > 7500) return 0.06;
  return findBracket(sponsorPv) - +findBracket(downlinePv);
}

export function tls(str, num) {
  return str.toLocaleString(undefined, {
    minimumFractionDigits: num || 0,
    maximumFractionDigits: num || 0,
  });
}

export function findLevel(bracket) {
  if (!bracket) return null;
  const bkt = parseInt(bracket, 10);
  if (bkt === 3) return 100;
  if (bkt === 6) return 300;
  if (bkt === 9) return 600;
  if (bkt === 12) return 1000;
  if (bkt === 15) return 1500;
  if (bkt === 18) return 2500;
  if (bkt === 21) return 4000;
  if (bkt === 23) return 6000;
  if (bkt === 25) return 7500;
  if (bkt === 27) return 10000;
  if (bkt === 29) return 12500;
  if (bkt === 31) return 15000;
  return null;
}
