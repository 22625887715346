import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

import Loading from '../Universal/Loading';
import Notification from './Timeline/Notification';

const Timeline = () => {
  const { data, error, loading } = useQuery(NOTIFICATION_QUERY, {
    suspend: false,
  });
  if (error) return `Timeline View: ${error.message}`;
  if (loading) return <Loading />;

  return (
    <React.Fragment>
      {data.mainTimeline.map((note, index) => (
        <Notification note={note} key={index} />
      ))}
    </React.Fragment>
  );
};

export default Timeline;

const NOTIFICATION_QUERY = gql`
  query {
    mainTimeline {
      timestamp
      prevTimestamp
      value
      firstName
      lastName
      secondaryFirstName
      iboshipId
      prevValue
      prevTimestamp
      categoryId
    }
  }
`;
