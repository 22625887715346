import React from 'react';

const StraightConnector = () => (
  <div style={{ position: 'relative', width: 24 }}>
    <div style={{ position: 'absolute', left: 12, top: -30 }}>
      <svg
        width="2"
        height="55"
        viewBox="0 0 2 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 0V55" stroke="#C1C7D0" strokeWidth="2" />
      </svg>
    </div>
  </div>
);
export default StraightConnector;
