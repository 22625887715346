import React, { useState } from 'react';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import SwipeableViews from 'react-swipeable-views';

import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import EventItem from './EventItem';
import Loading from '../../Universal/Loading';

const EventPicker = ({ open, onClose }) => {
  const [view, setView] = useState(0);

  const {
    data: { events },
    error,
    loading,
  } = useQuery(EVENT_LIST_QUERY, {
    suspend: false,
  });
  if (error) return `Structure View: ${error.message}`;
  const conferences = events
    ? events
        .filter(x => ['6'].includes(x.category.id))
        .map(event => (
          <EventItem key={event.id} event={event} handleClose={onClose} />
        ))
    : [];

  const teamMeetings = events
    ? events
        .filter(x => ['4'].includes(x.category.id))
        .map(event => (
          <EventItem key={event.id} event={event} handleClose={onClose} />
        ))
    : [];
  const myInfoSession = events
    ? events
        .filter(x => ['1', '2', '3'].includes(x.category.id))
        .map(event => (
          <EventItem key={event.id} event={event} handleClose={onClose} />
        ))
    : [];

  console.log(events);
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      scroll="paper"
    >
      <Tabs
        style={{ backgroundColor: 'white' }}
        value={view}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        scrollable
      >
        <Tab label="Conferences" />
        <Tab label="Team Meetings" />
        <Tab label="Info Sessions" />
      </Tabs>
      <DialogContent style={fontFix}>
        <SwipeableViews axis="x" index={view} onChangeIndex={handleChangeIndex}>
          <div dir="x">{loading ? <Loading /> : conferences}</div>
          <div dir="x">{loading ? <Loading /> : teamMeetings}</div>
          <div dir="x">{loading ? <Loading /> : myInfoSession}</div>
        </SwipeableViews>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
  function handleChange(event, viewNum) {
    setView(viewNum);
  }

  function handleChangeIndex(index) {
    setView(index);
  }
};

const fontFix = {
  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif`,
};

export default EventPicker;

const EVENT_LIST_QUERY = gql`
  query EventsListQuery($categoryId: Int) {
    events(categoryId: $categoryId) {
      name
      id
      startTime
      category {
        id
        name
      }
      location {
        id
        name
      }
    }
  }
`;
