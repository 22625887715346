import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-apollo-hooks';

import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';

import BottomNav from './Universal/BottomNav';
import Events from './GroupView/Events/Events';
import VolumeView from './GroupView/Volume/VolumeView';
import Dashboard from './Dashboard/Dashboard';
import Loading from './Universal/Loading';
import StructureView from './GroupView/StructureView';

const User = ({ history }) => {
  const handleChange = value => {
    history.push(`/${value}`);
  };
  const { data, error, loading } = useQuery(USER_INFO_QUERY, {
    suspend: false,
  });

  if (error) return `User Info Request: ${error.message}`;
  if (loading) return <Loading />;
  console.log(data);
  return (
    <UserContainer>
      <ContentWrapper>
        <Switch>
          <Route path="/events" component={Events} />
          <Route path="/volume" component={VolumeView} />
          <Route
            path="/settings"
            render={props => (
              <StructureView {...props} userInfo={data.userInfo} />
            )}
          />
          <Route
            path="/dashboard"
            render={props => <Dashboard {...props} userInfo={data.userInfo} />}
          />
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </ContentWrapper>

      <BottomNav handleChange={handleChange} />
    </UserContainer>
  );
};

export default withRouter(User);

export const USER_INFO_QUERY = gql`
  query MyiboshipQuery {
    # myIboship {
    #   id
    #   firstName
    #   secondaryFirstName
    #   lastName
    #   awardPv
    # }
    userInfo {
      iboship {
        id
      }
      user {
        id
        myPerson {
          isPrimary
          firstName
          lastName
        }
      }
    }
  }
`;

const ContentWrapper = styled.div`
  padding-bottom: 56px;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f4f5f7;
`;
