import React from 'react';
import styled from 'styled-components';
import formatDistance from 'date-fns/formatDistance';

const Registration = ({ iboshipId, value, prevTimestamp }) => {
  const prev = !prevTimestamp
    ? null
    : formatDistance(new Date(prevTimestamp), new Date(), {
        includeSeconds: true,
      });
  return (
    <RegCard>
      <div className="bigNote">New Partnership</div>
      {!prev ? (
        <span>
          This is {iboshipId}
          's first partnership!
        </span>
      ) : (
        <span>
          Now at {value} active partnerships. It's been
          {` ${prev}`} since their last partnership.
        </span>
      )}
    </RegCard>
  );
};

export default Registration;

const RegCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
