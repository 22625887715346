import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo-hooks';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const EditDetails = ({ iboship, refetch }) => {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState(iboship.firstName || undefined);
  const [secondaryFirstName, setSecondaryFirstName] = useState(
    iboship.secondaryFirstName || undefined
  );

  const updateNames = useMutation(UPDATE_NAME);

  const handleSave = async mutation => {
    const { iboNumber } = iboship;
    await mutation({
      variables: {
        iboNumber,
        firstName,
        secondaryFirstName,
      },
    });
    refetch();
    setOpen(false);
  };

  return (
    <EditContainer>
      <IconButton
        onClick={setOpen(true)}
        style={{
          color: '#C1C7D0',
        }}
        aria-label="Edit"
      >
        <InfoIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">IBOship Details</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            id="firstName"
            label="Primary First Name"
            onChange={e => setFirstName(e.target.value)}
            value={firstName}
            fullWidth
            style={{ margin: 8 }}
          />
          <TextField
            variant="outlined"
            id="secondaryFirstName"
            label={
              secondaryFirstName
                ? 'Secondary First Name'
                : 'Add Secondary First Name'
            }
            onChange={e => setSecondaryFirstName(e.target.value)}
            value={secondaryFirstName}
            fullWidth
            style={{ margin: 8 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={setOpen(false)} color="primary">
            Cancel
          </Button>

          <Button
            onClick={() => handleSave(updateNames)}
            color="primary"
            disabled={
              firstName === iboship.firstName &&
              secondaryFirstName === iboship.secondaryFirstName
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </EditContainer>
  );
};

export default EditDetails;

const EditContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
`;

const UPDATE_NAME = gql`
  mutation UpdateName(
    $firstName: String!
    $iboNumber: Int!
    $secondaryFirstName: String
  ) {
    updateNames(
      iboNumber: $iboNumber
      firstName: $firstName
      secondaryFirstName: $secondaryFirstName
    ) {
      result {
        success
        message
      }
    }
  }
`;
