import React from 'react';
import styled from 'styled-components';

const VolSpan = ({
  bv = false,
  pv = false,
  digits = 2,
  courier = false,
  bolder = true,
}) => (
  <React.Fragment>
    <Number courier={courier}>
      {((bv || pv) * 0.01).toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      })}
    </Number>
    <BvLabel courier={courier} bolder={bolder}>
      {bv ? 'BV' : 'PV'}
    </BvLabel>
  </React.Fragment>
);
export default VolSpan;

const BvLabel = styled.span`
  font-size: 90%;
  font-weight: ${({ bolder }) => bolder && 'bolder'};
  opacity: 0.7;
  ${props =>
    props.courier
      ? `font-family: 'Courier New', Courier, monospace;
  font-weight: 700;
  margin-left: 3px;`
      : `
  margin-left: 4px;`};
`;

const Number = styled.span`
  ${props =>
    props.courier &&
    `font-family: 'Courier New', Courier, monospace;
  font-weight: 600;`};
`;
