import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Login from './Login';
import Logout from './Logout';
import { AUTH_TOKEN } from '../constants';
import User from './User';

const Routes = () => (
  <BrowserRouter>
    <Root>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/logout" exact component={Logout} />
        <PrivateRoute path="/" component={User} />
      </Switch>
    </Root>
  </BrowserRouter>
);
export default Routes;

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const authToken = localStorage.getItem(AUTH_TOKEN);

      return authToken != null ? (
        <User {...props} />
      ) : (
        <Redirect to="/logout" />
      );
    }}
  />
);

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  width: 100%;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: 1.42857142857143;
  color: #172b4d;
  letter-spacing: -0.005em;
`;
