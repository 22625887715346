import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

import CircularProgress from '@material-ui/core/CircularProgress';
import VolSpan from '../../Universal/VolSpan';
import MoneySpan from '../../Universal/MoneySpan';
import BracketSpan from '../../Universal/BracketSpan';
import GraphSwitch from './GraphSwitch';
import { tls } from '../../../utils';

const VolumeHeader = ({ month, year, selectedIbo }) => {
  const [personalToggled, setPersonalToggled] = useState(false);
  const [awardToggled, setAwardToggled] = useState(false);
  const [incomeToggled, setIncomeToggled] = useState(false);
  const [calcToggled, setCalcToggled] = useState(false);
  const [toggleMonths, setToggleMonths] = useState(false);

  const {
    data: { headerStats },
    loading,
  } = useQuery(HEADER_STATS_QUERY, {
    variables: { month: month + 1, year, iboshipId: selectedIbo },
    suspend: false,
  });
  let leadershipBonuses;
  let personalCircle;
  let pvChecks;
  let bracket;
  if (!loading) {
    ({
      leadership_bonuses: leadershipBonuses,
      personal_circle: personalCircle,
      pv_checks: pvChecks,
    } = JSON.parse(headerStats.incomeCalc));

    ({ bracket } = headerStats);
  }
  return (
    <VolumeHeadContainer>
      <StatsRow>
        <StatContainer onClick={() => setPersonalToggled(!personalToggled)}>
          <StatLabel>Personal {personalToggled ? 'BV' : 'PV'}</StatLabel>
          <StatValue>
            {loading ? (
              <CircularProgress size={16} />
            ) : (
              <span>
                {personalToggled
                  ? tls(headerStats.personalBv * 0.01, 2)
                  : tls(headerStats.personalPv * 0.01, 2)}
              </span>
            )}
          </StatValue>
        </StatContainer>

        <StatContainer onClick={() => setToggleMonths(!toggleMonths)}>
          <StatLabel>QCPV</StatLabel>
          <StatValue>
            {loading ? (
              <CircularProgress size={16} />
            ) : (
              tls(headerStats.customerPv * 0.01, 2)
            )}
          </StatValue>
        </StatContainer>

        <StatContainer onClick={() => setAwardToggled(!awardToggled)}>
          <StatLabel>Award {awardToggled ? 'BV' : 'PV'}</StatLabel>
          <StatValue>
            {loading ? (
              <CircularProgress size={16} />
            ) : (
              <span>
                {awardToggled
                  ? tls(headerStats.awardBv * 0.01, 2)
                  : tls(headerStats.awardPv * 0.01, 2)}
              </span>
            )}{' '}
          </StatValue>
        </StatContainer>
        <StatContainer onClick={_handleMoneyButton}>
          <StatLabel>Income</StatLabel>
          <StatValue>
            {loading ? (
              <CircularProgress size={16} />
            ) : (
              <span>
                {incomeToggled ? (
                  <span>${tls(headerStats.income * 0.01, 2)}</span>
                ) : (
                  <span style={{ opacity: 0.4 }}>Show</span>
                )}
              </span>
            )}
          </StatValue>
        </StatContainer>
      </StatsRow>
      <GraphArea>
        {!calcToggled ? (
          <GraphSwitch
            year={year}
            month={month}
            selectedIbo={selectedIbo}
            toggleMonths={toggleMonths}
          />
        ) : (
          <div>
            <div style={{ overflow: 'auto', height: 300, padding: 12 }}>
              <div>
                Personal Circle:{' '}
                <VolSpan
                  bv={(personalCircle && personalCircle.personal_bv) || 0}
                />{' '}
                &times; <BracketSpan percent={bracket} /> ={' '}
                <MoneySpan money={personalCircle.pv_check} />
              </div>
              {leadershipBonuses && leadershipBonuses.length > 0 && (
                <div>
                  <h4>Leadership Bonuses:</h4>
                  <div>
                    {leadershipBonuses.map(bonus => (
                      <div key={bonus.id} style={{ marginBottom: 8 }}>
                        <div>
                          {bonus.last_name} | {bonus.id}
                        </div>
                        <div>
                          6% Bonus: <MoneySpan money={bonus.leadership_bonus} />
                        </div>
                        <div>
                          Passed-up:{' '}
                          <MoneySpan money={bonus.passup_bv * 0.06} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {pvChecks && pvChecks.filter(x => x.pv_check > 0).length > 0 && (
                <React.Fragment>
                  <h4>PV Checks:</h4>
                  <div>
                    {pvChecks
                      .filter(x => x.pv_check > 0)
                      .map(bonus => (
                        <div key={bonus.id} style={{ marginBottom: 8 }}>
                          <div>
                            {bonus.last_name} | {bonus.id}
                          </div>
                          <div>
                            <VolSpan bv={bonus.award_bv} /> &times;{' '}
                            <BracketSpan percent={bracket - bonus.bracket} /> ={' '}
                            <MoneySpan money={bonus.pv_check} />
                          </div>
                          {bonus.passed_up > 0 && (
                            <div>
                              Passed-up: <MoneySpan money={bonus.passed_up} />
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </GraphArea>
    </VolumeHeadContainer>
  );

  function _handleMoneyButton() {
    if (incomeToggled) {
      if (calcToggled) {
        setCalcToggled(false);
        setIncomeToggled(false);
      } else {
        setCalcToggled(true);
      }
    } else {
      setIncomeToggled(true);
    }
  }
};

export default VolumeHeader;

const HEADER_STATS_QUERY = gql`
  query HeaderStatsQuery($iboshipId: Int, $month: Int, $year: Int) {
    headerStats(iboshipId: $iboshipId, month: $month, year: $year) {
      personalPv
      personalBv
      customerPv
      awardPv
      awardBv
      income
      incomeCalc
      bracket
    }
  }
`;

const GraphArea = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const StatContainer = styled.div`
  background-color: #ebecf0;
  border-radius: 2px;
  display: inline-flex;
  flex-direction: column;
  padding: 6px 10px;
  margin: 0px 3px;
  height: 48px;
`;

const StatLabel = styled.div`
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #7a869a;
  font-weight: 600;
  min-width: 48px;
  white-space: nowrap;
`;

const StatValue = styled.div`
  margin-top: 6px;
  font-size: 16px;
  color: #172b4d;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;
`;

const StatsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
`;

const VolumeHeadContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  position: fixed;
  top: 50px;
  z-index: 12;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09),
    0 10px 25px 0 rgba(37, 41, 82, 0.06);
`;
