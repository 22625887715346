import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';

import { findLevel, tls } from '../../utils';

const PinGroup = ({ bracket, pinsArray }) => {
  const pins = pinsArray
    .filter(x => x.value === bracket)
    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  let pinCount = pins.length;
  if (pinCount) {
    const filtered = pins.filter(x => !x.repeat);
    pinCount = filtered.length;
  }
  return (
    <PinGroupDiv>
      <div className="level">
        {pinCount > 2 && <span className="pin-count">{pinCount} x</span>}
        <span>{tls(findLevel(bracket))} PV</span>
      </div>
      <div className="iboGrouping">
        {pins &&
          pins.map((x, i) => (
            <div className={x.repeat ? 'repeatIbo' : 'pinIbo'} key={i}>
              <div className="pinTime">
                {format(new Date(x.timestamp), 'do')}
              </div>
              {x.firstName}{' '}
              {x.secondaryFirstName && `& ${x.secondaryFirstName} `}
              {x.lastName}
            </div>
          ))}
      </div>
    </PinGroupDiv>
  );
};

export default PinGroup;

const PinGroupDiv = styled.div`
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  overflow: hidden;
  margin: 8px 16px;
  box-shadow: 0 8px 20px 0 rgba(9, 30, 66, 0.12), 0 4px 12px rgba(0, 0, 0, 0.18);

  .level {
    font-weight: 700;
    color: #344563;
    background-color: #ebecf0;
    padding: 8px;
    font-size: 20px;
    display: flex;
    min-width: 110px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pin-count {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 4px;
    }
  }
  .pinIbo {
    margin: 4px 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: inherit;
    flex-grow: 0;
    overflow: hidden;
    .pinTime {
      display: inline-flex;
      justify-content: flex-end;
      color: #97a0af;
      min-width: 40px;
      margin-right: 8px;
    }
  }
  .repeatIbo {
    margin: 4px 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: inherit;
    flex-grow: 0;
    overflow: hidden;
    color: #97a0af;
    .pinTime {
      color: #b3bac5;
      display: inline-flex;
      justify-content: flex-end;

      min-width: 40px;
      margin-right: 8px;
    }
  }
  .iboGrouping {
    display: flex;
    flex-grow: 0;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px;
    overflow: auto;
  }
`;
