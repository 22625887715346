import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EventView from './EventView';

const Events = () => (
  <Switch>
    <Route path="/events/:id" component={EventView} />
    <Route path="/events" exact component={EventView} />
  </Switch>
);
export default Events;
