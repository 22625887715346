import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => (
  <LoadingContainer>
    <CenterIt>
      <CircularProgress size={50} />
    </CenterIt>
  </LoadingContainer>
);

export default Loading;

const LoadingContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex: 1;
  flex-direction: column;
`;

const CenterIt = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
`;
