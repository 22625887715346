import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-apollo-hooks';
import addMonths from 'date-fns/addMonths';
import gql from 'graphql-tag';
import format from 'date-fns/format';
import isSameMonth from 'date-fns/isSameMonth';

import Button from '@material-ui/core/Button';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ReplayIcon from '@material-ui/icons/Replay';

import Loading from '../../Universal/Loading';
import IboCard from './IboCard';
import VolumeHeader from './VolumeHeader';

const VolumeView = () => {
  const [selectedIbo, setSelectedIbo] = useState(null);
  const [monthDate, setMonthDate] = useState(new Date());

  const _changeMonth = async num => {
    const newDate = addMonths(monthDate, num);
    setMonthDate(newDate);
  };

  const nextEnabled = isSameMonth(monthDate, new Date());
  const resetEnabled = nextEnabled && !selectedIbo;
  const month = monthDate.getMonth();
  const year = monthDate.getFullYear();
  const fetchMonth = month + 1;
  const { data, error, loading } = useQuery(FRONTLINE_QUERY, {
    variables: { month: fetchMonth, year, includeZero: false },
    suspend: false,
  });

  return (
    <UniversalOuter>
      <HeaderContainer>
        {!selectedIbo
          ? `${format(monthDate, 'LLLL yyyy')} Volume`
          : `${format(monthDate, 'LLL yy')} Volume for ${selectedIbo.name}`}
      </HeaderContainer>
      {error ? (
        `Error fetching frontline: ${error.message}`
      ) : (
        <UniversalOuter>
          <VolumeHeader
            month={month}
            year={year}
            selectedIbo={selectedIbo && selectedIbo.id}
          />
          <HeaderSpacer />
          <VolumeGroupContainer>
            {loading ? (
              <Loading />
            ) : (
              data.frontlineMonth &&
              data.frontlineMonth.map((dayStat, index) => (
                <IboCard
                  key={dayStat.id}
                  dayStat={dayStat}
                  selectIbo={_selectIbo}
                  selectedIbo={selectedIbo && selectedIbo.id}
                  month={month}
                  year={year}
                  connectors={[]}
                  frontline
                  style={{ marginBottom: 20 }}
                  sponsorIsLast={index + 1 === data.frontlineMonth.length}
                />
              ))
            )}
          </VolumeGroupContainer>
          <div className="fabContainer">
            <Button
              variant="fab"
              mini
              color="primary"
              aria-label="Previous Month"
              className="prevFab"
              onClick={handlePrev}
            >
              <ChevronLeft />
            </Button>
            <Button
              variant="fab"
              color="secondary"
              aria-label="Reset"
              className="resetFab"
              disabled={resetEnabled}
              onClick={handleReset}
            >
              <ReplayIcon />
            </Button>
            <Button
              variant="fab"
              mini
              color="primary"
              aria-label="Previous Month"
              className="nextFab"
              disabled={nextEnabled}
              onClick={handleNext}
            >
              <ChevronRight />
            </Button>
          </div>
        </UniversalOuter>
      )}
    </UniversalOuter>
  );
  function handleNext() {
    _changeMonth(1);
  }

  function handlePrev() {
    _changeMonth(-1);
  }

  function handleReset() {
    setMonthDate(new Date());
    setSelectedIbo(null);
  }
  function _selectIbo(iboshipId) {
    setSelectedIbo(iboshipId);
  }
};

const UniversalOuter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  .prevFab {
  }
  .nextFab {
  }
  .resetFab {
    margin: 0px 24px;
  }
  .fabContainer {
    position: fixed;
    bottom: 72px;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    z-index: 20;
  }
`;

export default VolumeView;

const HeaderSpacer = styled.div`
  max-height: 270px;
  height: calc(100vw * 0.3 + 120px);
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  color: #fff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  font-size: 18px;
  font-weight: 600;
  background-color: #253858;
  position: fixed;
  top: 0;
  z-index: 12;
`;
const VolumeGroupContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  margin-bottom: 80px;
  padding-left: 16px;
`;

export const FRONTLINE_QUERY = gql`
  query FrontlineQuery(
    $iboshipId: Int
    $includeZero: Boolean
    $year: Int
    $month: Int
  ) {
    frontlineMonth(
      includeZero: $includeZero
      iboshipId: $iboshipId
      year: $year
      month: $month
    ) {
      id
      personalPv
      customerPv
      awardPv
      bracket
      incomeCalc
      iboship {
        id
        lastName
        platinum
      }
    }
  }
`;
