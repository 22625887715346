import React from 'react';
import styled from 'styled-components';

import StraightConnector from '../../Universal/StraightConnector';

const StructurePadding = ({ depthArray }) => (
  <PaddingWrapper>
    {depthArray.map(a =>
      a === 'space' ? <NoConnector /> : <StraightConnector />
    )}
  </PaddingWrapper>
);

export default StructurePadding;

const PaddingWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  position: relative;
`;
const NoConnector = styled.div`
  width: 24px;
  height: 38px;
  position: relative;
`;
