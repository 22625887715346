import React from 'react';
import styled from 'styled-components';

const BracketSpan = ({ percent, digits = 0, courier = false }) => (
  <React.Fragment>
    <Number courier={courier}>
      {percent.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      })}
    </Number>
    <BracketLabel>%</BracketLabel>
  </React.Fragment>
);

export default BracketSpan;

const BracketLabel = styled.span`
  font-size: 90%;
  font-weight: 600;
  opacity: 0.8;
  padding-left: 1px;
  font-family: Arial, Helvetica, sans-serif;
`;

const Number = styled.span`
  ${props =>
    props.courier &&
    `font-family: 'Courier New', Courier, monospace;
  font-weight: 600;`};
`;
