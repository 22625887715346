import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';

import { useQuery } from 'react-apollo-hooks';
import Loading from '../Universal/Loading';
import NewIboCard from './NewIboCard';

const NewIbos = () => {
  const {
    data: { newIbos },
    error,
    loading,
  } = useQuery(NEW_IBOS_QUERY, {
    suspend: false,
  });
  if (loading) return <Loading />;
  if (error) return `Timeline View: ${error.message}`;
  console.log(newIbos);
  return (
    <NewIboContainer>
      {newIbos && newIbos.map((ibo, i) => <NewIboCard ibo={ibo} key={i} />)}
    </NewIboContainer>
  );
};

export default NewIbos;

const NewIboContainer = styled.div`
  padding: 0px 16px;
`;

const NEW_IBOS_QUERY = gql`
  query {
    newIbos {
      lastName
      firstName
      secondaryLastName
      regDate
      lineOfSponsorship {
        id
        lastName
      }
    }
  }
`;
