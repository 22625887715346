export function findBracket(pv) {
  if (!pv) return null;
  if (pv < 100) {
    return 0.0;
  }
  if (pv < 300 && pv >= 100) {
    return 0.03;
  }
  if (pv < 600 && pv >= 300) {
    return 0.06;
  }
  if (pv < 1000 && pv >= 600) {
    return 0.09;
  }
  if (pv < 1500 && pv >= 1000) {
    return 0.12;
  }
  if (pv < 2500 && pv >= 1500) {
    return 0.15;
  }
  if (pv < 4000 && pv >= 2500) {
    return 0.18;
  }
  if (pv < 6000 && pv >= 4000) {
    return 0.21;
  }
  if (pv < 7500 && pv >= 6000) {
    return 0.23;
  }
  if (pv < 10000 && pv >= 7500) {
    return 0.25;
  }
  if (pv < 12500 && pv >= 10000) {
    return 0.27;
  }
  if (pv < 15000 && pv >= 12500) {
    return 0.29;
  }
  if (pv >= 15000) {
    return 0.31;
  }
  return null;
}

export function howCloseToNextPin(pv) {
  let percent = null;
  let warning = null;

  if (!pv) {
    console.log("Didn't receive PV to assess how close.");
    return { warning: 2, percent: 0 };
  }
  if (pv < 10000) {
    percent = pv / 10000.0;
    warning = pv < 5000 ? 0 : pv < 7500 ? 1 : pv < 8500 ? 2 : 3;
  } else if (pv < 30000 && pv >= 10000) {
    percent = (pv - 10000) / 20000.0;
    warning = pv < 20000 ? 0 : pv < 25000 ? 1 : pv < 28000 ? 2 : 3;
  } else if (pv < 60000 && pv >= 30000) {
    percent = (pv - 30000) / 30000.0;
    warning = pv < 48000 ? 0 : pv < 52000 ? 1 : pv < 56000 ? 2 : 3;
  } else if (pv < 100000 && pv >= 60000) {
    percent = (pv - 60000) / 40000.0;
    warning = pv < 82000 ? 0 : pv < 90000 ? 1 : pv < 94000 ? 2 : 3;
  } else if (pv < 150000 && pv >= 100000) {
    percent = (pv - 100000) / 50000.0;
    warning = pv < 130000 ? 0 : pv < 136000 ? 1 : pv < 142000 ? 2 : 3;
  } else if (pv < 250000 && pv >= 150000) {
    percent = (pv - 150000) / 100000.0;
    warning = pv < 215000 ? 0 : pv < 225000 ? 1 : pv < 240000 ? 2 : 3;
  } else if (pv < 400000 && pv >= 250000) {
    percent = (pv - 250000) / 150000.0;
    warning = pv < 350000 ? 0 : pv < 370000 ? 1 : pv < 385000 ? 2 : 3;
  } else if (pv < 600000 && pv >= 400000) {
    percent = (pv - 400000) / 200000.0;
    warning = pv < 540000 ? 0 : pv < 560000 ? 1 : pv < 580000 ? 2 : 3;
  } else if (pv < 750000 && pv >= 600000) {
    percent = (pv - 600000) / 150000.0;
    warning = pv < 660000 ? 0 : pv < 690000 ? 1 : pv < 715000 ? 2 : 3;
  } else if (pv < 1000000 && pv >= 750000) {
    percent = (pv - 750000) / 250000.0;
    warning = pv < 900000 ? 0 : pv < 940000 ? 1 : pv < 960000 ? 2 : 3;
  } else if (pv < 1250000 && pv >= 1000000) {
    percent = (pv - 1000000) / 250000.0;
    warning = pv < 1150000 ? 0 : pv < 1190000 ? 1 : pv < 1210000 ? 2 : 3;
  } else if (pv < 1500000 && pv >= 1250000) {
    percent = (pv - 1250000) / 250000.0;
    warning = pv < 1350000 ? 0 : pv < 1400000 ? 1 : pv < 1450000 ? 2 : 3;
  } else if (pv >= 1500000) {
    percent = 1.0;
    warning = 0;
  }
  return { warning, percent };
}
