import React, { useState } from 'react';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import Connector from '../Universal/Connector';
import StructurePadding from './Settings/StructurePadding';
import SettingsIboCard from './Settings/SettingsIboCard';

const StructureIbo = ({ iboship, allIbos, lastLeg, depthArray, refetch }) => {
  const [hidden, setHidden] = useState(iboship.hidden || false);

  const _handleSwitch = async () => {
    setHidden(!hidden);
    await switchIbo();
  };

  const { id, depth, active, num } = iboship;
  const switchIbo = useMutation(SWITCH_IBO, {
    variables: { iboshipId: id },
  });
  const frontline = [].concat(allIbos).filter(a => a.sponsorId === id);
  let newDepthArray = [];

  if (lastLeg) {
    newDepthArray = ['space'];
  } else if (depth > 0) {
    newDepthArray = ['line'];
  }

  if (depthArray.length >= 1) {
    newDepthArray = depthArray.concat(newDepthArray);
  }

  return (
    <Wrapper>
      <Container>
        <StructurePadding depthArray={depthArray || []} />
        {num > 1 && (
          <LevelSpan>
            <Connector />
          </LevelSpan>
        )}
        <SettingsIboCard
          iboship={iboship}
          active={active}
          hidden={hidden}
          refetch={refetch}
        />

        <Switch
          checked={!hidden}
          onChange={() => _handleSwitch(switchIbo, id)}
          style={{ marginTop: -6, marginBottom: -6 }}
        />
      </Container>
      <FrontlineWrapper>
        {frontline &&
          frontline.map((a, index) => (
            <StructureIbo
              refetch={refetch}
              allIbos={allIbos}
              iboship={a}
              lastLeg={
                frontline.length === 1 ? true : frontline.length - 1 === index
              }
              depthArray={newDepthArray}
              key={a.id}
            />
          ))}
      </FrontlineWrapper>
    </Wrapper>
  );
};

export default StructureIbo;

const SWITCH_IBO = gql`
  mutation SwitchIbo($iboshipId: Int!) {
    switchIbo(iboshipId: $iboshipId) {
      hidden
    }
  }
`;

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const FrontlineWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
`;
const LevelSpan = styled.div`
  position: relative;
  width: 24px;
  height: 36px;
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  background-color: #f4f5f7;
  padding: 4px 0px;
`;
