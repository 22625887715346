import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

import subMonths from 'date-fns/subMonths';
import format from 'date-fns/format';
import isSameMonth from 'date-fns/isSameMonth';
import Loading from '../Universal/Loading';
import PinGroup from './PinGroup';

function getAllIndexes(arr, val) {
  const indexes = [];
  let i;
  for (i = 0; i < arr.length; i++)
    if (arr[i].iboshipId === val) indexes.push(i);
  return indexes;
}

const NewPins = () => {
  const today = new Date();
  const monthAgo = subMonths(new Date(), 1);
  const { data, error, loading } = useQuery(MONTH_PINS_QUERY, {
    suspend: false,
  });

  if (error) return `Timeline View: ${error.message}`;
  if (loading) return <Loading />;
  const bracketsArray = [];
  const lastBracketsArray = [];
  let thisMonthPins = [];
  let lastMonthPins = [];
  let pinCount = 0;
  let lastPinCount = 0;
  if (data.monthPins) {
    thisMonthPins = data.monthPins.filter(x =>
      isSameMonth(today, new Date(x.timestamp))
    );
    lastMonthPins = data.monthPins.filter(x =>
      isSameMonth(monthAgo, new Date(x.timestamp))
    );

    thisMonthPins.forEach((x, i) => {
      if (bracketsArray.indexOf(x.value) === -1) bracketsArray.push(x.value);
      const indexes = getAllIndexes(thisMonthPins, x.iboshipId);
      if (indexes && indexes.length > 1) {
        console.log(x.iboshipId, indexes);
        if (i !== indexes[0]) {
          x.repeat = true;
        } else {
          x.repeat = false;
        }
      }
    });
    lastMonthPins.forEach((x, i) => {
      if (lastBracketsArray.indexOf(x.value) === -1)
        lastBracketsArray.push(x.value);
      const indexes = getAllIndexes(thisMonthPins, x.iboshipId);
      if (indexes && indexes.length > 1) {
        console.log(x.iboshipId, indexes);
        if (i !== indexes[0]) {
          x.repeat = true;
        } else {
          x.repeat = false;
        }
      }
    });

    const filtered = thisMonthPins.filter(x => !x.repeat);
    const lastFiltered = lastMonthPins.filter(x => !x.repeat);
    pinCount = filtered.length;
    lastPinCount = lastFiltered.length;
  }
  console.log(thisMonthPins);
  return (
    <div>
      <span style={titleStyle}>
        <span>
          {format(new Date(), 'MMMM')} Pins{pinCount > 0 && `: ${pinCount}`}
        </span>
      </span>
      {bracketsArray &&
        bracketsArray.map(x => (
          <PinGroup bracket={x} pinsArray={thisMonthPins} key={x} />
        ))}
      <span style={titleStyle}>
        <span>
          {format(new Date(lastMonthPins[0].timestamp), 'MMMM')} Pins
          {pinCount > 0 && `: ${lastPinCount}`}
        </span>
      </span>
      {lastBracketsArray &&
        lastBracketsArray.map(x => (
          <PinGroup bracket={x} pinsArray={lastMonthPins} key={x} />
        ))}
    </div>
  );
};

export default NewPins;

const MONTH_PINS_QUERY = gql`
  query {
    monthPins {
      value
      lastName
      firstName
      secondaryFirstName
      iboshipId
      timestamp
    }
  }
`;

const titleStyle = {
  fontSize: 32,
  margin: ' 16px 16px 0px 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  color: '#42526E',
};
