import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import Graph from './Graph';

const GraphSwitch = ({ month, year, selectedIbo: iboshipId, toggleMonths }) => {
  let graphDays = [];
  let graphMonths = [];
  if (!toggleMonths) {
    ({
      data: { graphDays },
    } = useQuery(AWARD_PV_DAYS_QUERY, {
      variables: {
        month: month + 1,
        year,
        iboshipId,
      },
      suspend: false,
    }));
  } else {
    ({
      data: { graphMonths },
    } = useQuery(AWARD_PV_MONTHS_QUERY, {
      variables: {
        month: month + 1,
        year,
        iboshipId,
      },
      suspend: false,
    }));
  }
  return (
    <div style={{ padding: 20, fontSize: 18 }}>
      <a href="https://testflight.apple.com/join/UjwHVTZm">
        Access the new more powerful graphs through the mobile app. Click here
        to get access to the beta through TestFlight.
      </a>
    </div>
  );
  return (
    <React.Fragment>
      {!toggleMonths ? (
        <Graph year={year} month={month} data={graphDays} />
      ) : (
        <Graph year={year} month={month} data={graphMonths} months />
      )}
    </React.Fragment>
  );
};

export default GraphSwitch;

const AWARD_PV_DAYS_QUERY = gql`
  query IboGraphDays($iboshipId: Int, $month: Int, $year: Int) {
    graphDays(iboshipId: $iboshipId, month: $month, year: $year)
  }
`;
const AWARD_PV_MONTHS_QUERY = gql`
  query IboGraphMonths($iboshipId: Int, $month: Int, $year: Int) {
    graphMonths(iboshipId: $iboshipId, month: $month, year: $year)
  }
`;
