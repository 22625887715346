import React from 'react';
import styled from 'styled-components';

import EditDetails from './EditDetails';

const SettingsIboCard = ({ iboship, active, hidden, refetch }) => {
  const { firstName, secondaryFirstName, lastName } = iboship;
  return (
    <Iboship active={active} hidden={hidden}>
      {firstName} {secondaryFirstName && `& ${secondaryFirstName} `}
      {lastName}
      <NumSpan>
        <EditDetails iboship={iboship} refetch={refetch} />
      </NumSpan>
    </Iboship>
  );
};

export default SettingsIboCard;

const Iboship = styled.div`
  -webkit-transform: translate3d(0, 0, 0);
  display: inline-flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: white;
  padding: 0px 14px;
  height: 38px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 500;
  z-index: 3;
  opacity: ${({ hidden }) => hidden && '0.5'};
  box-shadow: 0 15px 35px 0 rgba(9, 30, 66, 0.12),
    0 5px 15px rgba(0, 0, 0, 0.06);
  border-top: ${({ active }) => !active && '2px solid tomato'};
`;

const NumSpan = styled.span`
  color: #a5adba;
  margin-left: 6px;
  height: 38px;
  width: 42px;
  margin-right: -6px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
