import React from 'react';
import { VictoryTooltip } from 'victory';
import { mLabel, dLabel } from '../Graph';

const CustomLabel = props => {
  const { datum, months } = props;
  const { day, current, previous, month } = datum;
  const fontSelection =
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif";
  const label = months
    ? mLabel(month, current, previous)
    : dLabel(day, current, previous, new Date().getMonth());
  return (
    <g>
      {/* <VictoryLabel {...this.props} /> */}
      <VictoryTooltip
        {...props}
        x={240}
        y={80}
        text={label}
        orientation="top"
        pointerLength={0}
        cornerRadius={5}
        width={300}
        height={48}
        flyoutStyle={{
          fill: '#0747A6',
          opacity: 0.7,
          fontFamily: fontSelection,
        }}
      />
    </g>
  );
};

export default CustomLabel;

CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;
