import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import TimelineIcon from '@material-ui/icons/Timeline';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';

const BottomNav = ({ location, handleChange }) => {
  const { pathname } = location;
  let settingsOpacity = { opacity: 0.5 };
  let volumeOpacity = { opacity: 0.5 };
  let dashboardOpacity = { opacity: 0.5 };
  let eventsOpacity = { opacity: 0.5 };
  if (pathname === '/settings') {
    settingsOpacity = { opacity: 1 };
  }
  if (pathname.includes('events')) {
    eventsOpacity = { opacity: 1 };
  }
  if (pathname === '/dashboard') {
    dashboardOpacity = { opacity: 1 };
  }
  if (pathname === '/volume') {
    volumeOpacity = { opacity: 1 };
  }
  // const disabledOpacity = { opacity: 0.15 };

  return (
    <NavContainer>
      <AppBar position="sticky" color="primary">
        <Toolbar>
          <IconButton
            color="inherit"
            style={dashboardOpacity}
            onClick={() => handleChange('dashboard')}
          >
            <TimelineIcon />
          </IconButton>
          <div>
            <IconButton
              color="inherit"
              style={volumeOpacity}
              onClick={() => handleChange('volume')}
            >
              <DashboardRoundedIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </NavContainer>
  );
};

export default withRouter(BottomNav);

const NavContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
`;
