import React from 'react';
import styled from 'styled-components';

const MoneySpan = ({ money, digits = 2, courier = false }) => (
  <BvSpan>
    <DollarSign>$</DollarSign>
    <Number courier={courier}>
      {(money * 0.01).toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      })}
    </Number>
  </BvSpan>
);

export default MoneySpan;

const DollarSign = styled.span`
  /* font-size: 90%; */
  font-weight: 600;
  opacity: 0.8;
  padding-right: 1px;
`;

const BvSpan = styled.span``;
const Number = styled.span``;
