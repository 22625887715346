import React, { useState } from 'react';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { withRouter } from 'react-router';
import Timeline from './Timeline';

import { AUTH_TOKEN } from '../../constants';
import NewPins from './NewPins';
import NewIbos from './NewIbos';

const Dashboard = ({ history, userInfo }) => {
  const [view, setView] = useState(1);

  const _logout = async () => {
    await localStorage.removeItem(AUTH_TOKEN);
    history.push('/logout');
  };
  console.log(userInfo);
  const { user, iboship } = userInfo;
  const { firstName, lastName } = user.myPerson;

  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <UserNameText>
            {firstName} {lastName} <Sixty>| {iboship.id}</Sixty>
          </UserNameText>
          <LogOutButton onClick={() => _logout()}>Log Out</LogOutButton>
        </Toolbar>
        <Tabs
          style={{ backgroundColor: 'white' }}
          value={view}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Timeline" />
          <Tab label="New Pins" />
          <Tab label="New IBOs" />
        </Tabs>
      </AppBar>
      <HeaderPadding />
      <SwipeableViews axis="x" index={view} onChangeIndex={handleChangeIndex}>
        <div dir="x">
          <Timeline userInfo={userInfo} />
        </div>

        <div dir="x">
          <NewPins />
        </div>

        <div dir="x">
          <NewIbos />
        </div>
      </SwipeableViews>
    </React.Fragment>
  );
  function handleChange(event, viewNum) {
    setView(viewNum);
  }

  function handleChangeIndex(index) {
    setView(index);
  }
};

export default withRouter(Dashboard);

const HeaderPadding = styled.div`
  height: 112px;
  width: 100%;
`;
const Sixty = styled.span`
  color: #6b778c;
`;

const UserNameText = styled.div`
  font-size: 16px;
  font-weight: 600;
  flex-grow: 1;
`;

const LogOutButton = styled.button`
  display: flex;
  align-items: center;
  color: #ffffff66;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
`;
