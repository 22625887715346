import React, { useState } from 'react';
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { AUTH_TOKEN } from '../constants';

const Login = ({ history, classes }) => {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const loginMutation = useMutation(LOGIN_MUTATION, {
    variables: { password, username },
  });

  const _confirm = async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const result = await loginMutation({
        variables: {
          username,
          password,
        },
      });
      localStorage.setItem(AUTH_TOKEN, result.data.tokenAuth.token);
      history.push(`/`);
    } catch (error) {
      setErrorMessage(error.message);
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <ContentPage id="cTwaqi">
      <ContentWrapper>
        <LoginCard>
          <Typography
            variant="h4"
            color="inherit"
            gutterBottom
            style={{ marginBottom: 20, marginLeft: 6 }}
          >
            Login
          </Typography>
          <TextField
            label="Username"
            id="username"
            className={classes.textField}
            value={username}
            onChange={e => setUsername(e.target.value)}
            autoComplete="ibocalc-username"
            style={{ marginBottom: 20 }}
          />

          <TextField
            label="Password"
            id="password"
            className={classes.textField}
            value={password}
            type="password"
            onChange={e => setPassword(e.target.value)}
            autoComplete="ibocalc-password"
            style={{ marginBottom: 20 }}
            onKeyPress={catchReturn}
          />
          {errorMessage && (
            <Typography align="center" color="error">
              Please enter valid credentials.
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => _confirm()}
            size="large"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </LoginCard>
      </ContentWrapper>
    </ContentPage>
  );
  function catchReturn(e) {
    if (e.key === 'Enter') {
      _confirm();
      e.preventDefault();
    }
  }
};

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

export default withStyles(styles)(withRouter(Login));

const LoginCard = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.09),
    0 15px 35px 0 rgba(37, 41, 82, 0.06);
  padding: 20px 30px;
  border-radius: 7px;
  margin: auto;
`;

const ContentPage = styled.div`
  margin: 0px;
  transition: margin-left 0.25s;
`;

const ContentWrapper = styled.div`
  align-items: center;
  justify-content: center;
  height: 60vh;
  min-height: 400px;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  position: relative;
`;

const LOGIN_MUTATION = gql`
  mutation LoginMutation($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
    }
  }
`;
