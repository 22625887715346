import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import {
  VictoryBar,
  VictoryLine,
  VictoryLabel,
  VictoryChart,
  VictoryAxis,
} from 'victory';
import CustomLabel from './utils/CustomLabel';
import { tls } from '../../../utils';

export default class Graph extends PureComponent {
  render() {
    const { data, month, months } = this.props;

    let maxPv;
    let linesArray = [];
    if (data) {
      const parsedData = JSON.parse(data);

      const maxPrevPv = Math.max(...parsedData.map(x => x.previous));
      const maxCurrentPv = Math.max(...parsedData.map(x => x.current));
      maxPv = maxPrevPv > maxCurrentPv ? maxPrevPv : maxCurrentPv;

      if (maxPv >= 30000 && maxPv < 60000) {
        linesArray = [10000, 30000, 60000];
      } else if (maxPv >= 10000 && maxPv < 30000) {
        linesArray = [10000, 15000, 30000];
      } else if (maxPv < 10000) {
        linesArray = [10000, 15000];
      } else {
        const filteredBrackets = [].concat(bracketLines).filter(x => x > maxPv);

        const nextIndex = bracketLines.indexOf(filteredBrackets[0]);
        linesArray = [
          bracketLines[nextIndex],
          bracketLines[nextIndex - 1],
          bracketLines[nextIndex - 2],
          bracketLines[nextIndex - 3],
        ];
      }
    }
    const fontSelection =
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif";

    return (
      <GraphWrapper>
        <svg style={{ height: 0, position: 'absolute' }}>
          <defs>
            <linearGradient id="lgrad" x1="50%" y1="100%" x2="50%" y2="0%">
              <stop
                offset="0%"
                style={{ stopColor: 'rgb(255,139,0)', stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: 'rgb(255,171,0)', stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
        </svg>
        <div className="graph_size">
          {!data ? (
            <CircularProgress />
          ) : (
            <VictoryChart
              padding={{ left: 16, right: 16, top: 0, bottom: 20 }}
              width={480}
              height={150}
              domain={months ? { x: [1, 12] } : { x: [1, 31] }}
              domainPadding={
                months ? { x: 13, y: [0, 1] } : { x: 7, y: [0, 1] }
              }
            >
              <VictoryBar
                animate={{ duration: 500, onLoad: { duration: 600 } }}
                labels={d =>
                  months
                    ? mLabel(d.month, d.current, d.previous)
                    : dLabel(d.day, d.current, d.previous, month)
                }
                labelComponent={<CustomLabel months={months} />}
                data={JSON.parse(data)}
                x={months ? 'month' : 'day'}
                y="current"
                barRatio={1}
                style={{
                  data: {
                    fill: 'url(#lgrad)',
                    opacity: 1,
                    width: months && 24,
                  },
                  labels: {
                    fill: 'white',
                    fontFamily: fontSelection,
                    fontWeight: 600,
                    letterSpacing: '1px',
                    fontSize: 20,
                  },
                }}
              />
              <VictoryBar
                animate={{ duration: 500, onLoad: { duration: 600 } }}
                data={JSON.parse(data)}
                labels={d =>
                  months
                    ? mLabel(d.month, d.current, d.previous)
                    : dLabel(d.day, d.current, d.previous, month)
                }
                labelComponent={<CustomLabel months={months} />}
                x={months ? 'month' : 'day'}
                y="previous"
                barRatio={1}
                style={{
                  data: {
                    fill: '#42526E',
                    opacity: 0.5,
                  },
                  labels: {
                    fill: 'white',
                    fontFamily: fontSelection,
                    fontWeight: 600,
                    letterSpacing: '1px',
                    fontSize: 20,
                  },
                }}
              />

              {linesArray.map(value => (
                <VictoryLine
                  key={value}
                  textAnchor="end"
                  style={{
                    data: {
                      stroke: '#172B4D',
                      strokeWidth: 1,
                      opacity: 0.2,
                    },
                    labels: {
                      angle: 0,
                      fill: '#172B4D',
                      fontSize: 14,
                      opacity: 0.5,
                      fontFamily: fontSelection,
                      fontWeight: 600,
                      letterSpacing: '0.6px',
                    },
                  }}
                  labels={[`${tls(value * 0.01)}`]}
                  labelComponent={<VictoryLabel x={40} dy={26} />}
                  y={() => value}
                />
              ))}
              <VictoryAxis
                animate={{ duration: 500, onLoad: { duration: 600 } }}
                tickCount={months ? 6 : 16}
                tickFormat={x => x}
                style={{
                  axis: { stroke: '#6B778C' },
                  tickLabels: {
                    marginTop: 0,
                    fill: '#B3BAC5',
                    padding: 4,
                    fontFamily: fontSelection,
                    fontWeight: 600,
                  },
                }}
              />
            </VictoryChart>
          )}
        </div>
      </GraphWrapper>
    );
  }
}

const GraphWrapper = styled.div`
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  overflow: hidden;
  .graph_size {
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    width: 100vw;
    max-width: 600;
    max-height: 200;
    min-height: 160;
  }
`;

const bracketLines = [
  10000,
  30000,
  60000,
  100000,
  150000,
  250000,
  400000,
  600000,
  750000,
  1000000,
  1250000,
  1500000,
  1750000,
  2000000,
  2250000,
];

const monthsArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function mLabel(month, current, previous) {
  if (current === 0 || previous === 0) {
    return `${monthsArray[month - 1]}: ${
      current > 0 ? tls(current * 0.01) : tls(previous * 0.01)
    } PV`;
  }
  if (current > previous) {
    return `${monthsArray[month - 1]}: ${tls(current * 0.01)} PV  (+${tls(
      (current - previous) * 0.01
    )} PV)`;
  }
  return `${monthsArray[month - 1]}: ${tls(current * 0.01)} PV  (-${tls(
    (previous - current) * 0.01
  )} PV)`;
}

export function dLabel(day, current, previous, currentMonth) {
  if (current === 0 || previous === 0) {
    return `${currentMonth + 1}/${day}: ${
      current > 0 ? tls(current * 0.01) : tls(previous * 0.01)
    } PV`;
  }
  if (current > previous) {
    return `${currentMonth + 1}/${day}:  ${tls(current * 0.01)} PV   (+${tls(
      (current - previous) * 0.01
    )} PV)`;
  }
  return `${currentMonth + 1}/${day}:  ${tls(current * 0.01)} PV   (-${tls(
    (previous - current) * 0.01
  )} PV)`;
}
