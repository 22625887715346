import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

const EventItem = ({ history, handleClose, event }) => {
  const close = () => {
    history.push(`/events/${event.id}`);
    handleClose();
  };
  const { name } = event;
  return (
    <ListItem onClick={() => close()}>
      <span>{name}</span>
    </ListItem>
  );
};

export default withRouter(EventItem);

const ListItem = styled.div`
  border: 1px solid #ebecf0;
  border-radius: 3px;
  padding: 8px 16px;
  margin: 8px 0px;
`;
