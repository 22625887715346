import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import client from './apollo';
import Routes from './routes';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#172B4D',
    },
    secondary: {
      main: '#FCA311',
    },
    background: {
      default: '#E5E5E5',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    useNextVariants: true,
  },
});

const App = (
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline>
          <Routes />
        </CssBaseline>
      </MuiThemeProvider>
    </ApolloHooksProvider>
  </ApolloProvider>
);

ReactDOM.render(App, document.getElementById('root'));
